import React from "react";

// ONLINE PATH EDITOR: 
// https://yqnn.github.io/svg-path-editor/

export const Borders = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="10 -5 300 610"
      preserveAspectRatio="none"
      className="frame"
      vectorEffect="non-scaling-stroke"
    >
      <g
        id="Borders"
        data-name="Borders"
        transform="translate(0) scale(1 1)"
        strokeWidth="1px"
      >
        <path d="M 0 0 c 0 0 77 -2 111 0 c 22 1 43 2 69 2 c 38 1 107 -1 197 -2 l 0 600 c -14 2 -89 3 -150 0 c -60 -1 -88 0 -150 0 L 0 600 Z" />
      </g>
    </svg>
  );
};

export const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.055"
      height="79.144"
      viewBox="0 0 33.055 79.144"
      className="arrow"
    >
      <g
        id="arrow_bow_right"
        data-name="arrow bow right"
        transform="translate(-1215.249 -897.786) rotate(12)"
        strokeWidth={1}
      >
        <path
          id="Path_64"
          data-name="Path 64"
          d="M-997.036,639.267s3.227,18.16,6.8,31.988c2.758,13.612,10.359,41.468,10.359,41.468"
          transform="translate(2387.864 -16.862)"
          fill="none"
          stroke="#707070"
        />
        <path
          id="Path_65"
          data-name="Path 65"
          d="M-1016.623,685.855l-5.774,17.292-16.924-6.707"
          transform="translate(2562.47 241.007) rotate(13)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export const Frame = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 1505.006 549.168"
      preserveAspectRatio="none"
      className="frame"
      vectorEffect="non-scaling-stroke"
    >
      <g
        id="Frame"
        data-name="Frame"
        transform="translate(16.006 1.026)"
        strokeWidth="1px"
      >
        <path d="M 166.054 -0.0267 C 87.6825 -0.0267 9.6461 0.5786 0.8275 0.6491 C 0.0558 4.7288 -3.4991 24.1671 -7.0133 53.9455 C -9.3336 73.6097 -11.1826 93.8685 -12.5087 114.1591 C -14.1659 139.5205 -15.0062 165.0031 -15.0062 189.8993 C -15.0062 218.5709 -13.9087 248.0349 -11.6505 279.9753 C -9.6869 307.7517 -7.1267 333.4631 -4.8677 356.1479 C -1.7192 387.7667 0.9999 415.0735 0.9999 436.7287 L 0.9999 544.9168 C 8.6575 545.0738 60.6987 546.0745 141.549 546.0745 C 192.8174 546.0745 245.459 545.6781 298.0117 544.8963 C 367.7106 543.8591 444.9175 542.1357 526.6577 540.3111 C 667.6089 537.1649 813.3589 533.9115 934.5375 533.9115 C 1019.1565 533.9115 1100.6917 534.4889 1176.8783 535.6279 C 1237.8285 536.5391 1295.5117 537.8097 1348.3256 539.4043 C 1430.4445 541.8837 1475.2615 544.3929 1482.7666 544.8309 C 1482.4468 539.5594 1481.0768 516.1115 1479.7223 479.9885 C 1478.8185 455.8833 1478.0985 430.9325 1477.5819 405.8287 C 1476.9365 374.4495 1476.6091 342.7415 1476.6091 311.5859 C 1476.6091 303.2359 1476.5665 293.2483 1476.5214 282.6741 C 1476.251 219.2589 1475.8422 123.4251 1482.855 89.5005 C 1485.4911 76.7465 1487.1332 64.1039 1487.7357 51.9239 C 1488.2178 42.1805 1488.0393 32.7095 1487.2056 23.7737 C 1486.0144 11.0096 1483.8036 2.9751 1483.1055 0.6686 L 909.0217 10.7183 L 908.9971 10.7175 L 593.5579 0.6567 C 592.3557 0.7356 569.5788 2.2231 540.4005 3.6919 C 490.5067 6.2035 460.5739 6.7307 444.2491 6.7307 C 417.3861 6.7307 401.9513 5.7513 382.4102 4.5115 C 361.0555 3.1567 334.4789 1.4703 282.6935 0.6555 C 253.9233 0.2029 214.6802 -0.0267 166.054 -0.0267 Z" />
      </g>
    </svg>
  );
};

export const BoxSvgs = ({ i }: { i: number }) => {
  switch (i) {
    case 1:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="-3 0 286.921 277.991"
          className="box"

        >
          <g
            id="Square1"
            data-name="Square1"
            transform="translate(5.96)"
            fill="#fff"
            stroke="var(--color-grey)"
          >
            <path d="M0,0S20.611,4.539,55.825,6.165,108.432,0,164.286,0H276s2.162,17.519,0,46.652c-.926,12.475-12.722,33.364-5.96,53.921,4.236,12.878,0,34.736,0,46.356,0,70.75,6.921,123.219,6.921,123.219s-22.22,7.842-91.22,7.842c-35.535,0-85.969-9.311-121.416-7.842-33.151,1.374-70.286,0-70.286,0S.961,298.753.961,228c0-27.309-6.921-92.917-6.921-149.528S0,0,0,0Z" />
          </g>
        </svg>
      );
    case 2:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="282.921"
          height="285.218"
          viewBox="0 0 282.921 286.218"
          className="box"
        >
          <g
            id="Square2"
            data-name="Square2"
            transform="translate(5.96 0.723)"
            fill="none"
            stroke="var(--color-grey)"
          >
            <path d="M0,0S17.371-1.626,52.585,0s42.522,0,98.376,0H276s2.162,17.519,0,46.652c-1.523,20.518-1.039,63.352-1.039,93,0,70.75,2,130.5,2,130.5H173.341c-35.535,0-82.46,11.382-117.907,12.851C22.284,284.374,0,283,0,283s.961,15.753.961-55c0-27.309-6.921-92.917-6.921-149.528S0,0,0,0Z" />
          </g>
        </svg>
      );
    case 3:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="282.921"
          height="285.218"
          viewBox="0 -3 286.921 289.218"
          className="box"
        >
          <g
            id="Square3"
            data-name="Square3"
            transform="translate(5.96 -1.723)"
            fill="none"
            stroke="var(--color-grey)"
          >
            <path d="M0,0S17.371-1.626,52.585,0,85,6.5,140.858,6.5,276,0,276,0s2.162,17.519,0,46.652c-1.523,20.518.961,59.717.961,89.361C276.961,206.763,276,283,276,283s-32.831,3.9-101.831,3.9c-35.535,0-83.288-5.373-118.735-3.9C22.284,284.374,0,283,0,283s.961,15.753.961-55c0-27.309-6.921-92.917-6.921-149.528S0,0,0,0Z" />
          </g>
        </svg>
      );
    case 4:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="282.921"
          height="285.218"
          viewBox="0 0 282.921 285.218"
          className="box"
        >
          <g
            id="Square4"
            data-name="Square4"
            transform="translate(5.96 0.723)"
            fill="none"
            stroke="var(--color-grey)"
          >
            <path d="M 0 1 H 52.585 S 287.792 14.979 282.078 3.6 S 278.162 17.519 276 46.652 C 274.477 67.17 268.432 94.032 268.432 123.676 C 268.432 194.427 276 283 276 283 S 242.828 277.309 173.828 277.309 C 138.293 277.309 90.881 281.531 55.434 283 C 22.284 284.374 0 283 0 283 V 226.442 C 0 199.133 0 140 0 104 C -0.3333 70 -6 42 0 0 Z" />
          </g>
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="282.921"
          height="285.218"
          viewBox="0 0 282.921 285.218"
          className="box"
        >
          <g
            id="Square5"
            data-name="Square5"
            transform="translate(5.96 0.723)"
            fill="none"
            stroke="var(--color-grey)"
          >
            <path d="M0,0H52.585a69.833,69.833,0,0,0,20,3.181C83.229,3.181,95.154,0,95.154,0H276s2.162,17.519,0,46.652c-1.523,20.518-7.568,47.38-7.568,77.024C268.432,194.427,276,283,276,283s-33.172-5.691-102.172-5.691c-35.535,0-82.947,4.222-118.394,5.691C22.284,284.374,0,283,0,283V226.442c0-27.309,7.342-106.174,7.342-106.174Z" />
          </g>
        </svg>
      );
  }
};

export const Chevron = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="9.333"
      // height="14.883"
      viewBox="0 0 9.333 14.883"
    >
      <path
        id="back"
        d="M7.855.857,15.333,8.4,12.7,11.1,7.855,15.74,6,13.926,11.688,8.4,6,2.711Z"
        transform="translate(-6 -0.857)"
      />
    </svg>
  );
};
